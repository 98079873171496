<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <textarea
        ref="textarea"
        v-model="$v.value.$model"
        class="form-control"
        :contenteditable="!isDisabled"
        :disabled="isDisabled"
        rows="8"
        :maxlength="field?.validations?.maxLength"
        @focus="showVariables = true"
        @keyup.esc="showVariables = !showVariables"
      />
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip :target="() => $refs.textarea" :errors="validationErrors" />
    <!------------ END: Error messages tooltip ------------>
    <!------------ START: VariablesDropdown ------------>
    <VariablesDropdown
      v-if="enableVariables && isMounted"
      v-model="showVariables"
      :filter="$v.value.$model"
      :el="$refs['textarea']"
      @select="setVariable"
    />
    <!------------ END: VariablesDropdown ------------>
  </div>
</template>

<script>
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import VariablesDropdown from "@/components/Tools/FormHelper/Components/VariablesDropdown/VariablesDropdown";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";
import {
  base,
  eagerValidation
} from "@/components/Tools/FormHelper/Helper/mixins";

export default {
  components: { ErrorsTooltip, FieldWrapper, VariablesDropdown },
  mixins: [base, eagerValidation],
  data() {
    return {
      isMounted: false,
      showVariables: false
    };
  },
  watch: {
    value: function () {
      const maxLength = this.field?.validations?.maxLength;

      if (maxLength && this.value?.length > maxLength) {
        this.$v.value.$model = this.value.substring(0, maxLength);
      }
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    setVariable(variable) {
      this.value = variable;
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  white-space: pre-wrap;
}
</style>
